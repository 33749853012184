/* Popeyes FONTS */

/* Google fonts helper
https: //google-webfonts-helper.herokuapp.com/fonts/barlow?subsets=latin */

@font-face {
  font-family: 'chicken_sans_bold';
  src: url('ChickenSans-Bold.woff2') format('woff2'), url('ChickenSans-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'chicken_sans';
  src: url('ChickenSans-Regular.woff2') format('woff2'), url('ChickenSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'chicken_sans_xlight';
  src: url('ChickenSans-ExtraLight.woff2') format('woff2'), url('ChickenSans-ExtraLight.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

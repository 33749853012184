/* Tim Hortons FONTS */

@font-face {
  font-family: 'sofia_pro';
  src: url('sofia_pro_black.otf') format('opentype'), url('sofia_pro_black.woff2') format('woff2'),
    url('sofia_pro_black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'sofia_pro';
  src: url('sofia_pro_bold.otf') format('opentype'), url('sofia_pro_bold.woff2') format('woff2'),
    url('sofia_pro_bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'sofia_pro';
  src: url('sofia_pro_regular.otf') format('opentype'),
    url('sofia_pro_regular.woff2') format('woff2'), url('sofia_pro_regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
